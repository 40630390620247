'use client';
import { useStore } from 'zustand';
import { type ReactNode, createContext, useRef, useContext } from 'react';
import { NewStore, createAsyncStore, NewStoreApi } from 'stores/storeFactory';

export const NewStoreContext = createContext<NewStoreApi | undefined>(
  undefined
);

export interface NewStoreProviderProps {
  children: ReactNode;
  initialData: Omit<NewStore, 'fetchData'>; // Initial state including server-fetched data but omit fetch function
}

// Custom Context Provider for sending store via context
export const NewStoreProvider = ({
  children,
  initialData,
}: NewStoreProviderProps) => {
  const storeRef = useRef<NewStoreApi>();

  if (!storeRef.current) {
    storeRef.current = createAsyncStore(initialData);
  }

  return (
    <NewStoreContext.Provider value={storeRef.current}>
      {children}
    </NewStoreContext.Provider>
  );
};

// Custom hook to access Zustand store in client components
export const useNewStore = <T,>(selector: (state: NewStore) => T): T => {
  const store = useContext(NewStoreContext);
  if (!store) {
    throw new Error('useGlobalStore must be used within GlobalStoreProvider');
  }
  return useStore(store, selector);
};
