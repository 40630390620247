import { createStore } from 'zustand/vanilla';
import fetchAPI from 'utils/api';

export type StoreState = {
  data: any;
};

export type NewStore = StoreState & StoreActions;

export type NewStoreApi = ReturnType<typeof createAsyncStore>;

export type StoreActions = {
  fetchData: (url: string, params: {}) => Promise<void>;
};

// Factory function to create the Zustand store
// We have to create and provide stores to the pages because
// all of our pages are client component and you cannot initiate
// an async function from a client, therefore we must fetch in a store
// then provide the data to the page as a useStore hook
export const createAsyncStore = (initState: StoreState) => {
  return createStore<NewStore>()((set) => ({
    // Pass non-fetched state for initialization
    ...initState,

    // Async action to fetch data to be called from server wrapper function
    fetchData: async (path: string, params: {}) => {
      try {
        const response = await fetchAPI(path, params);
        const data = await response;
        set({ data });
      } catch (error: any) {
        console.error('Failed to fetch hompage data');
      }
    },
  }));
};
